.border_right{
    border-right: solid 2px gray;
}

.scroll_table{
    overflow: auto;
    max-height: 300px;
}

.pdf_view{
    min-height: 400px;
}

.bg_toolbar{
    background-color: #4e6ece !important;
    /* color: #ffe600 !important; */
}

.bg_list_item{
    background-color: #6483e2 !important;
    color: white !important;
}

.cell_srno{
    min-width: 40px;
}

.cell_description{
    min-width: 400px;
}

.cell_quantity{
    min-width: 170px;
}

.cell_unit_price{
    min-width: 170px;
}

.cell_amount{
    min-width: 200px;
}

.cell_date{
    min-width: 160px;
}

.scroll_tab{
    overflow-y: auto;
    max-height: 450px;
}

.min_height_iframe{
    min-height: 500px;
}

.pointer{
    cursor: pointer !important;
}

.login_button{
    border-radius: 25px !important;
    background-color: #3AAF9F !important;
    color: white !important;
}

.welcome_text{
    font-weight: bolder;
    font-size: xx-large;
    color: white;
}

.login_screen_resolution{
    width: 850px;
    max-width: 850px;
} 

.small_button{
    background-color: #3AAF9F !important;
    color: white !important;
}

.app_bar{
    background-color: #71b874 !important; /* #A2E2B0 */
    color: white !important;
}

.table_clickable{
    pointer-events: all;
}

.table_non_clickable{
    pointer-events: none;
}

.scroll{
    overflow: auto !important;
}

.scroll_hidden{
    overflow: hidden !important;
}

.centered {
    position: fixed !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    -webkit-transform: translate(-50%, -50%) !important;
    -moz-transform: translate(-50%, -50%) !important;
    -o-transform: translate(-50%, -50%) !important;
    -ms-transform: translate(-50%, -50%) !important;
    z-index: 100 !important;
}

.pdf_bg{
    background-color: #525659 !important;
}

.side_images{
    width: 100%;
    height: auto;
}

.selected_img{
    border: 2px solid rgb(255, 217, 0);
    cursor: pointer;
}

.non_selected_img{
    border: 2px solid rgb(216, 213, 213);
    cursor: pointer;
}

.bg_choose_btn{
    background-color: #3AAF9F !important;
    color: white !important;
}

.noselect {
    -webkit-touch-callout: none !important; /* iOS Safari */
    -webkit-user-select: none !important; /* Safari */
    -khtml-user-select: none !important; /* Konqueror HTML */
    -moz-user-select: none !important; /* Firefox */
    -ms-user-select: none !important; /* Internet Explorer/Edge */
    user-select: none !important; /* Non-prefixed version, currently supported by Chrome and Opera */
    cursor: not-allowed !important;
}
.noselect:active {
    pointer-events: none !important;
}

.table_cell_text{
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}
